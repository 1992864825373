// regenerator-runtime/runtime must be the first import or at least before exceljs
// prettier-ignore-start
import "regenerator-runtime/runtime"; // Required for ExcelJS to work with bare.min.js version (which in turn is required to prevent unsafe-eval)
// prettier-ignore-end
import { TranslatedString } from "api-shared";

export enum TemplateType {
    MeasureFields = "measureFields",
    MeasureEffects = "measureEffects",
    Opps = "opps",
}

export const ConfigTemplateTypeKey = "templateType";
export const ConfigMeasureConfigIdKey = "measureConfigId";
export const ConfigFieldNamesKey = "fieldDisplayNames";
export const ConfigFieldFieldsKey = "fieldInternalFields";

// Label displayed to the user in the preview table and in the excel sheet
export const IdFieldDisplayName = "ID";
export const TitleFieldDisplayName = "Title";
export const FieldResponsiblePersonName = "Responsible person";

// Internal names for accessing those fields using row.fields[...]
export const IdFieldInternalName = "id";
export const TitleFieldInternalName = "title";
export const AssignedToInternalName = "assignedTo";

// Type definitions
export type ExcelImportConfig = {
    templateType: TemplateType;
    measureConfigId: number;
    fieldDisplayNames: string[];
    fieldInternalFields: string[];
};

export type ParsedExcelDataRow = Record<string, string>;
export type ParsedExcelData = { config: ExcelImportConfig; data: ParsedExcelDataRow[] };

export const convertAllowedValue = (allowedValue: string | number | TranslatedString) => {
    if (typeof allowedValue === "string") {
        return allowedValue;
    }
    if (typeof allowedValue === "number") {
        return allowedValue.toString();
    }

    const list = [allowedValue.name];
    if (allowedValue.nameTranslations !== undefined) {
        list.push(allowedValue.nameTranslations.en);
        list.push(allowedValue.nameTranslations.de);
    }
    return list;
};
