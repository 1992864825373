import { useCallback } from "react";
import { useBeforeUnload, useBlocker } from "react-router-dom";

/**
 * Hook that prompts the user before leaving the page if there are unsaved changes
 *
 * Takes care of both the native beforeunload event and also the react-router-dom navigation
 *
 * @param {boolean} isEnabled
 * @param {string} message a custom message that will be shown to the user when router navigation is attempted has been blocked
 */
function useNavigationPrompt(isEnabled: boolean, message: string): void {
    const handleUnload = useCallback(
        (event: { preventDefault: () => void; returnValue: string }) => {
            if (isEnabled) {
                event.preventDefault();
                event.returnValue = "Are you sure you want to leave? You have unsaved changes.";
            }
        },
        [isEnabled],
    );

    useBeforeUnload(handleUnload, { capture: true });

    // Firefox can't handle the unstable_usePrompt consistently resulting in an "Should not already be working." error
    // https://github.com/remix-run/react-router/issues/10314
    // Workaround to not use unstable_usePrompt
    const blocker = (currentLocation: string, nextLocation: string, message: string) => {
        if (nextLocation !== currentLocation.split("?")[0] && isEnabled) {
            const answer = window.confirm(message);
            return !answer;
        }
        return false;
    };
    useBlocker((history) => blocker(history.currentLocation.pathname, history.nextLocation.pathname, message));
}

export default useNavigationPrompt;
