import { Chip } from "@mui/material";
import { CellProps } from "react-table";
import MeasureIdChip from "../../../components/MeasureIdChip";
import { TemplateType } from "../../../lib/excel";
import { ValidatedExcelDataCell } from "../../../lib/excel-validate";
import { ImportTableData } from "./ImportTableWithHeader";

type IImportTableIdCellProps = CellProps<ImportTableData, ValidatedExcelDataCell> & { templateType: TemplateType };

const ImportTableIdCell = ({ value, templateType }: IImportTableIdCellProps) => {
    const hasError = value != undefined && value.errorMessage !== "";
    if (hasError) {
        return <Chip label="!" color="error" sx={{ height: (theme) => theme.spacing(2), minWidth: (theme) => theme.spacing(4) }} />;
    }

    const isMeasure = templateType !== TemplateType.Opps;
    return value?.value != null && value?.value !== "" && isMeasure ? (
        <MeasureIdChip measureId={Number(value.value)} />
    ) : (
        <Chip
            label={value?.value ? Number(value.value) : ""}
            color="default"
            sx={{ height: (theme) => theme.spacing(2), minWidth: (theme) => theme.spacing(4) }}
        />
    );
};

export default ImportTableIdCell;
