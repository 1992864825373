import { reportError } from "../infrastructure/sentry";

/**
 * @exports
 * @param {string} filename The name of the file which should be downloaded
 * @param {Blob} blob Blob data for the document
 */
export function useDownloadFile() {
    return (filename: string, blob: Blob) => {
        try {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "none";
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            reportError(error instanceof Error ? error : new Error("Error generating XLS export"), {
                extras: { cause: JSON.stringify(error) },
            });
        }
    };
}
