import { useTranslation } from "react-i18next";

import UploadRounded from "@mui/icons-material/UploadRounded";
import { Alert, Button, Grid, Paper, Stack, styled } from "@mui/material";
import { useState } from "react";
import UploadInput from "../../components/UploadInput";
import ActionItemDialog from "../../components/dialogues/ActionItemDialog";
import useDialog from "../../hooks/useDialog";
import { ParsedExcelData, TemplateType } from "../../lib/excel";
import { parseExcel } from "../../lib/excel-import";
import { tryTranslate } from "../../lib/translate";
import { translationKeys } from "../../translations/main-translations";
import ImportEffectData from "./ImportTable/ImportEffectData";
import ImportFieldData from "./ImportTable/ImportFieldData";
import ImportOppsData from "./ImportTable/ImportOppsData";

const Root = styled(Paper)({ height: "100%" });
const RootGrid = styled(Grid)({ height: "100%" });

const Grow = styled(Grid)({
    flexGrow: 1,
    flexShrink: 1,
});
const Header = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(2),
    height: theme.spacing(6),
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const ImportFileSection = () => {
    const { t } = useTranslation();

    const [fileToUpload, setFileToUpload] = useState({ selectedFile: null as File | null, hasValidFile: false });
    const [parsedExcelData, setParsedExcelData] = useState<ParsedExcelData | null>(null);
    const [parseError, setParseError] = useState<string>();

    const selectedFileChanged = async (selectedFile: File | null, hasValidFile: boolean) => {
        setFileToUpload({ selectedFile, hasValidFile });
        if (!hasValidFile || selectedFile === null) {
            return;
        }

        try {
            // try to parse the provided file to show parsing errors immediately
            await parseExcel(selectedFile);
            setParseError(undefined);
        } catch (e) {
            setParseError(e instanceof Error ? e.message : "Unknown error");
        }
    };

    const handleUploadClick = async () => {
        if (fileToUpload.selectedFile !== null && fileToUpload.hasValidFile) {
            const data = await parseExcel(fileToUpload.selectedFile);
            setParsedExcelData(data);
        }
    };

    const { isOpen, close, openDialog } = useDialog();

    const handleClose = () => {
        close();
        setFileToUpload({ selectedFile: null, hasValidFile: false });
    };

    const handleClearExcelData = () => {
        setParsedExcelData(null);
    };

    return (
        <Root>
            <RootGrid container direction="column" wrap="nowrap">
                <Grow item>
                    {parsedExcelData == null ? (
                        <Header direction="row" alignItems="center" justifyContent="flex-end" height="100%">
                            <Button size="small" startIcon={<UploadRounded />} variant="contained" onClick={openDialog}>
                                {t(translationKeys.VDLANG_DATA_IMPORT_UPLOAD_DOCUMENT)}
                            </Button>
                        </Header>
                    ) : null}
                    {parsedExcelData?.config.templateType === TemplateType.MeasureFields ? (
                        <ImportFieldData parsedExcelData={parsedExcelData} onClearExcelData={handleClearExcelData} />
                    ) : null}
                    {parsedExcelData?.config.templateType === TemplateType.MeasureEffects ? (
                        <ImportEffectData parsedExcelData={parsedExcelData} onClearExcelData={handleClearExcelData} />
                    ) : null}
                    {parsedExcelData?.config.templateType === TemplateType.Opps ? (
                        <ImportOppsData parsedExcelData={parsedExcelData} onClearExcelData={handleClearExcelData} />
                    ) : null}
                </Grow>
            </RootGrid>

            <ActionItemDialog
                action="upload"
                item="file"
                primary={translationKeys.VDLANG_UPLOAD}
                onPrimary={handleUploadClick}
                primaryDisabled={parseError != null || !fileToUpload.hasValidFile}
                translate={t}
                title={t(translationKeys.VDLANG_UPLOAD_SELECT_DOCUMENT)}
                onClose={handleClose}
                open={isOpen}
            >
                <Grid item>
                    <Stack spacing={2}>
                        <UploadInput
                            translate={t}
                            updateFile={selectedFileChanged}
                            file={fileToUpload.selectedFile}
                            mimeTypes={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                        />
                        {parseError != null ? (
                            <Alert severity="error">
                                {t(translationKeys.VDLANG_DATA_IMPORT_PARSE_ERROR, { error: tryTranslate(t, parseError) })}
                            </Alert>
                        ) : null}
                    </Stack>
                </Grid>
            </ActionItemDialog>
        </Root>
    );
};

export default ImportFileSection;
