import { TFunction } from "i18next";
import { ApiError } from "../../../lib/api";
import { getTranslatedErrorMessage } from "../../../lib/error";
import { ImportTableData } from "./ImportTableWithHeader";

export enum ImportTableStatus {
    NotStarted = "NotStarted",
    Pending = "Pending",
    Success = "Success",
    Error = "Error",
    Cancelled = "Cancelled",
}

export const updateTableItem = (
    oldTableItems: ImportTableData[],
    rowNumber: number,
    rowModifier: (row: ImportTableData) => ImportTableData,
) => {
    return oldTableItems.map((currentRow) => {
        if (currentRow.rowNumber === rowNumber) {
            return rowModifier(currentRow);
        }
        return currentRow;
    });
};

export const setRowError = (row: ImportTableData, e: unknown, translate: TFunction) => ({
    ...row,
    status: { value: ImportTableStatus.Error, errorMessage: e instanceof ApiError ? getTranslatedErrorMessage(e, translate) : "" },
});

export const setRowCancelled = (row: ImportTableData) => ({
    ...row,
    status: { value: ImportTableStatus.Cancelled, errorMessage: "" },
});

export const setRowAndFieldError = (row: ImportTableData, e: unknown, key: string, translate: TFunction) => {
    return {
        ...row,
        status: { value: ImportTableStatus.Error, errorMessage: e instanceof ApiError ? getTranslatedErrorMessage(e, translate) : "" },
        fields: {
            ...row.fields,
            [key]: {
                ...row.fields[key],
                errorMessage: e instanceof ApiError ? getTranslatedErrorMessage(e, translate) : "",
            },
        },
    };
};

export const setFinalStatus = (row: ImportTableData) => {
    return {
        ...row,
        status: { value: ImportTableStatus.Success, errorMessage: "" },
    };
};

export const setFinalStatusWithIds = (row: ImportTableData, measureId: number, displayId: number) => {
    return {
        ...row,
        apiId: measureId,
        status: { value: ImportTableStatus.Success, errorMessage: "" },
        fields: {
            ...row.fields,
            id: {
                value: displayId.toString(),
                errorMessage: "",
                fieldName: "ID",
            },
        },
    };
};

export function setRowPending(row: ImportTableData) {
    // Only set not started or cancelled rows to pending => rest is already in final state
    if (!(row.status.value === ImportTableStatus.NotStarted || row.status.value === ImportTableStatus.Cancelled)) {
        return row;
    }
    return {
        ...row,
        status: { value: ImportTableStatus.Pending, errorMessage: "" },
    };
}
