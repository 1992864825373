import { MeasureConfigDtoV1 } from "api-shared";
import { TFunction } from "i18next";
import Select from "../../components/input/select/Select";
import { ISelectProps, Option, ValueHandler } from "../../components/input/select/types";
import { translationKeys } from "../../translations/main-translations";

interface IMeasureConfigSelectProps extends Omit<ISelectProps<Option<number>>, "options" | "value" | "onChange"> {
    value: number;
    onChange: ValueHandler<number>;
    translate: TFunction;
    measureConfigs: MeasureConfigDtoV1[];
}

const MeasureConfigSelect = ({ value, translate, onChange, measureConfigs }: IMeasureConfigSelectProps) => {
    const measureConfigOptions = measureConfigs.map((measureConfig) => ({
        value: measureConfig.id,
        label: translate(measureConfig.name),
    }));
    const selectedMeasureConfigOption = measureConfigOptions.find(({ value: optionsValue }) => value === optionsValue);

    return (
        <Select
            label={translate(translationKeys.VDLANG_DATA_IMPORT_PROCESS_TYPE)}
            options={measureConfigOptions}
            value={selectedMeasureConfigOption}
            defaultValue={measureConfigOptions[0]}
            menuPortalTarget={document.body}
            isClearable={false}
            onChange={(option) => option != null && onChange(option.value)}
        />
    );
};

export default MeasureConfigSelect;
