import { useMutation, useQuery } from "@tanstack/react-query";
import { CreateOpportunityDtoV1, EditOpportunityDtoV1, NumericDisplayIdSelfDtoV1, OpportunityListDtoV1 } from "api-shared";
import { apiGet, apiPost, apiPut } from "../../lib/api";

const OPPS_PATH = "/v1/opportunity";

export const OpportunitiesQueryKeys = {
    all: [OPPS_PATH] as const,
};

export const useOpportunities = (onSuccess?: (data: OpportunityListDtoV1) => void) => {
    return useQuery({
        queryKey: OpportunitiesQueryKeys.all,
        queryFn: async ({ signal }) => {
            return apiGet<OpportunityListDtoV1>(OPPS_PATH, { signal });
        },
        onSuccess: (data) => onSuccess?.(data),
    });
};

export const useCreateOpportunity = () => {
    return useMutation({
        mutationFn: (body: CreateOpportunityDtoV1) => apiPost<NumericDisplayIdSelfDtoV1>(OPPS_PATH, body),
    });
};

// editOpportunity
export const useEditOpportunity = () => {
    return useMutation({
        mutationFn: ({ id, ...body }: EditOpportunityDtoV1 & { id: number }) => apiPut<void>(`${OPPS_PATH}/${id}`, body),
    });
};
