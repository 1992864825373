/* eslint-disable no-await-in-loop */
// https://eslint.org/docs/latest/rules/no-await-in-loop#when-not-to-use-it
import { zCreateOpportunityV1, zEditOpportunityV1 } from "api-shared";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useCreateOpportunity, useEditOpportunity, useOpportunities } from "../../../domain/v1/opportunity";
import { ParsedExcelData } from "../../../lib/excel";
import { validateOppsData } from "../../../lib/excel-validate";
import { translationKeys } from "../../../translations/main-translations";
import { useIdeaEstimates } from "../../ideas/hooks";
import ImportTableWithHeader, { ImportTableData, getValidatedDataWithStatus } from "./ImportTableWithHeader";
import {
    ImportTableStatus,
    setFinalStatus,
    setFinalStatusWithIds,
    setRowCancelled,
    setRowError,
    setRowPending,
    updateTableItem,
} from "./table_utils";
import useUploadNavigationPrompt from "./useUploadNavigationPrompt";

interface IImportFieldDataProps {
    parsedExcelData: ParsedExcelData;
    onClearExcelData: () => void;
}

const ImportOppsData = ({ parsedExcelData, onClearExcelData }: IImportFieldDataProps) => {
    const [validatedData, setValidatedData] = useState<ImportTableData[]>([]);
    const cancelStatus = useRef(false);

    const createOpportunityMutation = useCreateOpportunity();
    const editOpportunityMutation = useEditOpportunity();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const estimates = useIdeaEstimates();

    const revertUserEstimateToValue = (value: string, fieldName: "potentialEstimate" | "timeEstimate" | "effortEstimate") => {
        const fieldValues = estimates[`${fieldName}s`];
        return fieldValues.find((est) => est.label === value)?.value;
    };

    useUploadNavigationPrompt(validatedData);

    const useOpportunitiesQuery = useOpportunities((data) => {
        if (data === undefined || validatedData.length > 0) {
            return;
        }

        const mapDisplayIdToApiId = new Map(data.map((opps) => [opps.displayId, opps.id]));
        const validatedExcelData = validateOppsData(parsedExcelData, mapDisplayIdToApiId, estimates);
        setValidatedData(getValidatedDataWithStatus(validatedExcelData));
    });

    if (!useOpportunitiesQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    const resumeOn503 = (e: any) => {
        if ("code" in e && e.code == "503") {
            setValidatedData((prev) =>
                prev.map((item) => (item.status.value === ImportTableStatus.Pending ? setRowCancelled(item) : item)),
            );
        }
    };

    const handleImportClick = async () => {
        const importData = validatedData.map(setRowPending);
        setValidatedData([...importData]);
        for (const data of importData) {
            if (cancelStatus.current) {
                setValidatedData((prev) =>
                    prev.map((item) => (item.status.value === ImportTableStatus.Pending ? setRowCancelled(item) : item)),
                );
                enqueueSnackbar(t(translationKeys.VDLANG_DATA_IMPORT_INFO_CANCELLED), { variant: "error" });
                break;
            }
            const currentOpp = {
                title: data.fields.title.value,
                description: data.fields.description?.value ?? "",
                potentialEstimate: revertUserEstimateToValue(data.fields.potentialEstimate?.value, "potentialEstimate") ?? null,
                timeEstimate: revertUserEstimateToValue(data.fields.timeEstimate?.value, "timeEstimate") ?? null,
                effortEstimate: revertUserEstimateToValue(data.fields.effortEstimate?.value, "effortEstimate") ?? null,
            };

            try {
                if (data.apiId !== undefined) {
                    await editOpportunityMutation.mutateAsync(
                        {
                            ...zEditOpportunityV1.parse(currentOpp),
                            id: data.apiId,
                        },
                        { onError: (e: unknown) => resumeOn503(e) },
                    );
                    setValidatedData((prev) => updateTableItem(prev, data.rowNumber, (row) => setFinalStatus(row)));
                } else {
                    const oops = await createOpportunityMutation.mutateAsync(zCreateOpportunityV1.parse(currentOpp), {
                        onError: (e: unknown) => resumeOn503(e),
                    });
                    setValidatedData((prev) =>
                        updateTableItem(prev, data.rowNumber, (row) => setFinalStatusWithIds(row, oops.id, oops.displayId)),
                    );
                }
            } catch (e: unknown) {
                setValidatedData((prev) => updateTableItem(prev, data.rowNumber, (row) => setRowError(row, e, t)));
                return;
            }
        }
    };

    const handleImportCancelClick = () => {
        // cancelStatus update won't trigger a re-render but the update inside the loop will do => accepted
        cancelStatus.current = true;
    };
    const handleImportResumeClick = () => {
        cancelStatus.current = false;
        handleImportClick();
    };

    return (
        <ImportTableWithHeader
            validatedData={validatedData}
            parsedExcelData={parsedExcelData}
            onImportClick={handleImportClick}
            onClearExcelData={onClearExcelData}
            onImportCancelClick={handleImportCancelClick}
            onImportResumeClick={handleImportResumeClick}
        />
    );
};

export default ImportOppsData;
