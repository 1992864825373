import { useQuery } from "@tanstack/react-query";
import { CreateUpdateMeasureEffectMetaDtoV1, CreateUpdateMeasureFieldMetaDtoV1, MeasureConfigListDtoV1 } from "api-shared";
import { apiGet } from "../../lib/api";

export const GET_CURRENT_CLIENT = "GET_CURRENT_CLIENT";
export const GET_CURRENT_CLIENT_SUCCEEDED = "GET_CURRENT_CLIENT_SUCCEEDED";
export const GET_CURRENT_CLIENT_FAILED = "GET_CURRENT_CLIENT_FAILED";

const MEASURE_CONFIG_PATH = "v1/measureconfig";

export const V1MeasureConfigsQueryKeys = {
    all: ["measure_configs"] as const,
    effectsMeta: (measureconfigId: number) => ["measure_configs", "effects_meta", measureconfigId] as const,
    fieldsMeta: (measureconfigId: number) => ["measure_configs", "fields_meta", measureconfigId] as const,
};

export const useMeasureConfigsV1 = () => {
    return useQuery({
        queryKey: V1MeasureConfigsQueryKeys.all,
        queryFn: ({ signal }) => apiGet<MeasureConfigListDtoV1>(MEASURE_CONFIG_PATH, { signal }),
    });
};

export const useMeasureConfigsEffectsV1 = (
    measureConfigId: number,
    enabled = true,
    onSuccess?: (data: CreateUpdateMeasureEffectMetaDtoV1) => void,
) => {
    return useQuery({
        queryKey: V1MeasureConfigsQueryKeys.effectsMeta(measureConfigId),
        queryFn: ({ signal }) =>
            apiGet<CreateUpdateMeasureEffectMetaDtoV1>(`${MEASURE_CONFIG_PATH}/${measureConfigId}/effect/createmeta`, { signal }),
        onSuccess: (data) => onSuccess?.(data),
        enabled,
    });
};

export const useMeasureConfigsFieldsV1 = (
    measureConfigId: number,
    enabled = true,
    onSuccess?: (data: CreateUpdateMeasureFieldMetaDtoV1) => void,
) => {
    return useQuery({
        queryKey: V1MeasureConfigsQueryKeys.fieldsMeta(measureConfigId),
        queryFn: ({ signal }) =>
            apiGet<CreateUpdateMeasureFieldMetaDtoV1>(`${MEASURE_CONFIG_PATH}/${measureConfigId}/field/createmeta`, { signal }),
        onSuccess: (data) => onSuccess?.(data),
        enabled,
    });
};
