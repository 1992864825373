/* eslint-disable no-await-in-loop */
// https://eslint.org/docs/latest/rules/no-await-in-loop#when-not-to-use-it
import {
    CreateUpdateMeasureFieldMetaDtoV1,
    MeasureFieldNames,
    Sort,
    nonNullable,
    zCreateMeasureV1,
    zEditMeasureV1,
    zSearchV1,
    zUpdateMeasureFieldV1,
} from "api-shared";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useUsersQuery } from "../../../domain/users";
import { useCreateMeasure, useEditMeasure, useEditMeasureField } from "../../../domain/v1/measure";
import { useMeasureSearchQuery } from "../../../domain/v1/measure-search";
import { useMeasureConfigsFieldsV1 } from "../../../domain/v1/measure_config";
import {
    AssignedToInternalName,
    IdFieldDisplayName,
    IdFieldInternalName,
    ParsedExcelData,
    TitleFieldInternalName,
} from "../../../lib/excel";
import { validateMeasureFieldsData } from "../../../lib/excel-validate";
import { translationKeys } from "../../../translations/main-translations";
import ImportTableWithHeader, { ImportTableData, getValidatedDataWithStatus } from "./ImportTableWithHeader";
import {
    ImportTableStatus,
    setFinalStatusWithIds,
    setRowAndFieldError,
    setRowCancelled,
    setRowError,
    setRowPending,
    updateTableItem,
} from "./table_utils";
import useUploadNavigationPrompt from "./useUploadNavigationPrompt";

interface IImportFieldDataProps {
    parsedExcelData: ParsedExcelData;
    onClearExcelData: () => void;
}

const ImportFieldData = ({ parsedExcelData, onClearExcelData }: IImportFieldDataProps) => {
    const [validatedData, setValidatedData] = useState<ImportTableData[]>([]);
    const [fieldMetaData, setFieldMetaData] = useState<CreateUpdateMeasureFieldMetaDtoV1>();
    const cancelStatus = useRef(false);
    const usersQuery = useUsersQuery();

    const selectedUserId = (displayName: string) => {
        return usersQuery.data?.filter((user) => user.email === displayName)[0].id;
    };

    const createMeasureMutation = useCreateMeasure();
    const editMeasureMutation = useEditMeasure({ ignoreErrors: true });
    const editMeasureFieldMutation = useEditMeasureField({ ignoreErrors: true });
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useUploadNavigationPrompt(validatedData);

    // Make sure only valid ids are used for the idQuery
    const parsedIds = parsedExcelData.data
        .map((row) => (IdFieldDisplayName in row && !isNaN(Number(row[IdFieldDisplayName])) ? row[IdFieldDisplayName] : null))
        .filter(nonNullable);

    const isMeasureIdQueryNeeded = parsedIds.length > 0;
    const vqlIdsQuery = useMeasureSearchQuery(
        zSearchV1.parse({
            vql: `${MeasureFieldNames.ClientIid} IN [${parsedIds.toString()}]`,
            startAt: 0,
            maxResults: 100,
            sortBy: MeasureFieldNames.ClientIid,
            sortOrder: Sort.ASCENDING.toLowerCase(),
        }),
        isMeasureIdQueryNeeded,
    );
    useEffect(() => {
        if (vqlIdsQuery.hasNextPage !== true) {
            return;
        }
        vqlIdsQuery.fetchNextPage({ cancelRefetch: false });
    }, [vqlIdsQuery.data?.pages, vqlIdsQuery, vqlIdsQuery.hasNextPage]);

    const fieldMetaQuery = useMeasureConfigsFieldsV1(
        parsedExcelData.config.measureConfigId,
        (!vqlIdsQuery.hasNextPage && vqlIdsQuery.isSuccess) || !isMeasureIdQueryNeeded,
        (data) => {
            if ((isMeasureIdQueryNeeded && vqlIdsQuery.data === undefined) || validatedData.length > 0) {
                return;
            }
            const allData = vqlIdsQuery.data?.pages.flat() ?? [];
            const mapDisplayIdToApiId = new Map(allData.map((measure) => [measure.displayId, measure.id]));
            const validatedExcelData = validateMeasureFieldsData(parsedExcelData, data, mapDisplayIdToApiId);
            setValidatedData(getValidatedDataWithStatus(validatedExcelData));
            setFieldMetaData(data);
        },
    );

    const resumeOn503 = (e: any) => {
        if ("code" in e && e.code == "503") {
            setValidatedData((prev) =>
                prev.map((item) => (item.status.value === ImportTableStatus.Pending ? setRowCancelled(item) : item)),
            );
        }
    };

    const handleImportClick = async () => {
        if (fieldMetaData === undefined) {
            return;
        }

        const importData = validatedData.map(setRowPending);
        setValidatedData([...importData]);
        for (const data of importData) {
            if (cancelStatus.current) {
                setValidatedData((prev) =>
                    prev.map((item) => (item.status.value === ImportTableStatus.Pending ? setRowCancelled(item) : item)),
                );
                enqueueSnackbar(t(translationKeys.VDLANG_DATA_IMPORT_INFO_CANCELLED), { variant: "error" });
                break;
            }
            // Create or update measure first - Fields can only be created/updated if measure exists
            let measureId: number | null = null;
            let displayId: number | null = null;
            try {
                if (data.apiId == undefined) {
                    const measure = await createMeasureMutation.mutateAsync(
                        zCreateMeasureV1.parse({
                            title: data.fields[TitleFieldInternalName].value,
                            measureConfig: { id: parsedExcelData.config.measureConfigId },
                            assignedTo:
                                AssignedToInternalName in data.fields
                                    ? selectedUserId(data.fields[AssignedToInternalName].value)
                                    : undefined,
                        }),
                        { onError: (e: unknown) => resumeOn503(e) },
                    );
                    measureId = measure.id;
                    displayId = measure.displayId;
                    data.apiId = measureId;
                } else if (
                    (TitleFieldInternalName in data.fields && data.fields[TitleFieldInternalName].value !== "") ||
                    (AssignedToInternalName in data.fields && data.fields[AssignedToInternalName].value !== "")
                ) {
                    await editMeasureMutation.mutateAsync(
                        {
                            ...zEditMeasureV1.parse({
                                title: TitleFieldInternalName in data.fields ? data.fields[TitleFieldInternalName].value : null,
                                assignedTo:
                                    AssignedToInternalName in data.fields
                                        ? selectedUserId(data.fields[AssignedToInternalName].value)
                                        : undefined,
                            }),
                            id: data.apiId,
                        },
                        { onError: (e: unknown) => resumeOn503(e) },
                    );
                    measureId = data.apiId;
                    displayId = +data.fields[IdFieldInternalName].value;
                } else {
                    // Neither create not edit (of title) - just copy id to keep it
                    measureId = data.apiId;
                    displayId = +data.fields[IdFieldInternalName].value;
                }
            } catch (e: unknown) {
                setValidatedData((prev) => updateTableItem(prev, data.rowNumber, (row) => setRowError(row, e, t)));
            }

            // Update fields
            await Promise.all(
                Object.entries(data.fields).map(async ([key, value]) => {
                    if (
                        measureId == null ||
                        key === IdFieldInternalName ||
                        key === TitleFieldInternalName ||
                        key === "currency" /* not supported? */ ||
                        key === AssignedToInternalName
                    ) {
                        return;
                    }

                    try {
                        await editMeasureFieldMutation.mutateAsync(
                            {
                                ...zUpdateMeasureFieldV1.parse({
                                    name: key,
                                    value: value.value,
                                }),
                                id: measureId,
                            },
                            { onError: (e: unknown) => resumeOn503(e) },
                        );
                    } catch (e: unknown) {
                        setValidatedData((prev) => updateTableItem(prev, data.rowNumber, (row) => setRowAndFieldError(row, e, key, t)));
                    }
                }),
            );

            if (displayId !== null && measureId !== null) {
                setValidatedData((prev) =>
                    updateTableItem(prev, data.rowNumber, (row) => setFinalStatusWithIds(row, measureId, displayId)),
                );
            }
        }
    };
    const handleImportCancelClick = () => {
        // cancelStatus update won't trigger a re-render but the update inside the loop will do => accepted
        cancelStatus.current = true;
    };

    const handleImportResumeClick = () => {
        cancelStatus.current = false;
        handleImportClick();
    };

    if (!fieldMetaQuery.isSuccess && !usersQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    return (
        <ImportTableWithHeader
            validatedData={validatedData}
            parsedExcelData={parsedExcelData}
            onImportClick={handleImportClick}
            onClearExcelData={onClearExcelData}
            onImportCancelClick={handleImportCancelClick}
            onImportResumeClick={handleImportResumeClick}
        />
    );
};

export default ImportFieldData;
