/* eslint-disable no-await-in-loop */
import {
    DeleteMeasureEffectDtoV1,
    EffectFilterCurrencyField,
    GlobalCalculationIdentifier,
    MeasureFieldNames,
    Sort,
    UpdateMeasureEffectDtoV1,
    nonNullable,
    zDeleteMeasureEffectV1,
    zSearchV1,
    zUpdateMeasureEffectV1,
} from "api-shared";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useDeleteMeasureEffect, useEditMeasureEffect } from "../../../domain/v1/measure";
import { useMeasureSearchQuery } from "../../../domain/v1/measure-search";
import { useMeasureConfigsEffectsV1 } from "../../../domain/v1/measure_config";
import { IdFieldDisplayName, ParsedExcelData } from "../../../lib/excel";
import { validateMeasureEffectData } from "../../../lib/excel-validate";
import { translationKeys } from "../../../translations/main-translations";
import ImportTableWithHeader, { ImportTableData, getValidatedDataWithStatus } from "./ImportTableWithHeader";
import { ImportTableStatus, setFinalStatus, setRowAndFieldError, setRowCancelled, setRowPending, updateTableItem } from "./table_utils";
import useUploadNavigationPrompt from "./useUploadNavigationPrompt";

interface IImportFieldDataProps {
    parsedExcelData: ParsedExcelData;
    onClearExcelData: () => void;
}

const ImportEffectData = ({ parsedExcelData, onClearExcelData }: IImportFieldDataProps) => {
    const regexCheckEffectCategory = /EC-(?<month>\d{2})-(?<year>\d{4})-(?<type>.*)/m;

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [validatedData, setValidatedData] = useState<ImportTableData[]>([]);
    const cancelStatus = useRef(false);

    useUploadNavigationPrompt(validatedData);

    const editMeasureEffectMutation = useEditMeasureEffect();
    const deleteMeasureEffectMutation = useDeleteMeasureEffect();

    // Make sure only valid ids are used for the idQuery
    const parsedIds = parsedExcelData.data
        .map((row) => (IdFieldDisplayName in row && !isNaN(Number(row[IdFieldDisplayName])) ? row[IdFieldDisplayName] : null))
        .filter(nonNullable);

    const isMeasureIdQueryNeeded = parsedIds.length > 0;
    const vqlIdsQuery = useMeasureSearchQuery(
        zSearchV1.parse({
            vql: `${MeasureFieldNames.ClientIid} IN [${parsedIds.toString()}]`,
            startAt: 0,
            maxResults: 100,
            sortBy: MeasureFieldNames.ClientIid,
            sortOrder: Sort.ASCENDING.toLowerCase(),
        }),
        isMeasureIdQueryNeeded,
    );

    useEffect(() => {
        if (vqlIdsQuery.hasNextPage !== true) {
            return;
        }
        vqlIdsQuery.fetchNextPage({ cancelRefetch: false });
    }, [vqlIdsQuery.data?.pages, vqlIdsQuery, vqlIdsQuery.hasNextPage]);

    const effectMetaQuery = useMeasureConfigsEffectsV1(
        parsedExcelData.config.measureConfigId,
        (!vqlIdsQuery.hasNextPage && vqlIdsQuery.isSuccess) || !isMeasureIdQueryNeeded,
        (data) => {
            if ((isMeasureIdQueryNeeded && vqlIdsQuery.data === undefined) || validatedData.length > 0) {
                return;
            }
            const allData = vqlIdsQuery.data?.pages.flat() ?? [];
            const mapDisplayIdToApiId = new Map(allData.map((measure) => [measure.displayId, measure.id]));
            const validatedExcelData = validateMeasureEffectData(parsedExcelData, data, mapDisplayIdToApiId);
            setValidatedData(getValidatedDataWithStatus(validatedExcelData));
        },
    );

    const resumeOn503 = (e: any) => {
        if ("code" in e && e.code == "503") {
            setValidatedData((prev) =>
                prev.map((item) => (item.status.value === ImportTableStatus.Pending ? setRowCancelled(item) : item)),
            );
        }
    };

    const handleImportClick = async () => {
        if (effectMetaQuery.data === undefined) {
            return;
        }

        const importData = validatedData.map(setRowPending);
        setValidatedData([...importData]);

        // looping over all the entries of importData, updating them one by one
        for (const data of importData) {
            if (cancelStatus.current) {
                setValidatedData((prev) =>
                    prev.map((item) => (item.status.value === ImportTableStatus.Pending ? setRowCancelled(item) : item)),
                );
                enqueueSnackbar(t(translationKeys.VDLANG_DATA_IMPORT_INFO_CANCELLED), { variant: "error" });
                break;
            }

            // update effects sequentially to avoid reace conditions
            for (const [key, value] of Object.entries(data.fields)) {
                const ecMatches = key.match(regexCheckEffectCategory);

                // Only effect fields
                if (ecMatches === null || ecMatches.groups === undefined || data.apiId === undefined) {
                    continue;
                }

                // Update requires:
                // 1. measureId
                // 2. generation
                // 3. x* effect category
                // 4. initial/effect
                // 5. optional: currency

                const effectCategories = Object.keys(effectMetaQuery.data.effectCategories);
                const effectCategory: UpdateMeasureEffectDtoV1["effectCategory"] = {};
                Object.entries(data.fields)
                    .filter(([key, value]) => effectCategories.includes(key))
                    .forEach(([key, value]) => (effectCategory[key] = { name: value.value }));

                const effect: UpdateMeasureEffectDtoV1 = {
                    effect: +value.value,
                    generation: { name: data.fields.generation.value as GlobalCalculationIdentifier },
                    effectCategory,
                    month: +ecMatches.groups.month - 1, // EC-09-2023-initial
                    year: +ecMatches.groups.year, // EC-09-2023-initial
                    type:
                        ecMatches.groups.type.toLowerCase() === "initial"
                            ? EffectFilterCurrencyField.Initial
                            : EffectFilterCurrencyField.Effect,
                };

                if (data.fields.currency != null) {
                    const currency = effectMetaQuery.data.currencies.find((c) => c.name === data.fields.currency.value);

                    if (currency != null) {
                        effect.currencyId = currency.id;
                    }
                }

                try {
                    if (value.value.toLowerCase() === "x") {
                        const { effect: val, ...otherProps } = effect;
                        const deleteEffect: DeleteMeasureEffectDtoV1 = { ...otherProps };
                        await deleteMeasureEffectMutation.mutateAsync(
                            {
                                ...zDeleteMeasureEffectV1.parse(deleteEffect),
                                id: data.apiId,
                            },
                            { onError: (e: unknown) => resumeOn503(e) },
                        );
                    } else {
                        await editMeasureEffectMutation.mutateAsync(
                            {
                                ...zUpdateMeasureEffectV1.parse(effect),
                                id: data.apiId,
                            },
                            { onError: (e: unknown) => resumeOn503(e) },
                        );
                    }
                } catch (e: unknown) {
                    setValidatedData((prev) => updateTableItem(prev, data.rowNumber, (row) => setRowAndFieldError(row, e, key, t)));
                }
            }

            setValidatedData((prev) => updateTableItem(prev, data.rowNumber, (row) => setFinalStatus(row)));
        }
    };
    const handleImportCancelClick = () => {
        // cancelStatus update won't trigger a re-render but the update inside the loop will do => accepted
        cancelStatus.current = true;
    };

    const handleImportResumeClick = () => {
        cancelStatus.current = false;
        handleImportClick();
    };

    if (!effectMetaQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    return (
        <ImportTableWithHeader
            validatedData={validatedData}
            parsedExcelData={parsedExcelData}
            onImportClick={handleImportClick}
            onClearExcelData={onClearExcelData}
            onImportCancelClick={handleImportCancelClick}
            onImportResumeClick={handleImportResumeClick}
        />
    );
};

export default ImportEffectData;
