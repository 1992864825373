// regenerator-runtime/runtime must be the first import or at least before exceljs
// prettier-ignore-start
import "regenerator-runtime/runtime"; // Required for ExcelJS to work with bare.min.js version (which in turn is required to prevent unsafe-eval)
// prettier-ignore-end
import ExcelJS, { CellValue } from "exceljs";
import { translationKeys } from "../translations/main-translations";
import {
    ConfigFieldFieldsKey,
    ConfigFieldNamesKey,
    ConfigMeasureConfigIdKey,
    ConfigTemplateTypeKey,
    ExcelImportConfig,
    ParsedExcelData,
    ParsedExcelDataRow,
    TemplateType,
} from "./excel";

/*
Extracts the data from the Excel file into a single object.
The object contains the config and the data.
The config contains the template type, measure config id and field mappings.
The field mappings are used to map the display names to the internal field names even if the translations are changed in the app.
Extracting the columns is finnicky as they are 1-indexed. First row should always be the table header. 🫰
*/

// Extract config and data from excel file into a single object
export async function parseExcel(xlsFile: File): Promise<ParsedExcelData> {
    // Read the Excel file from input file
    const blobData = await xlsFile.arrayBuffer();
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(blobData);

    // Extract sheet data
    const configSheet = workbook.getWorksheet("vd_config");
    if (configSheet == null) {
        // Typings do not reflect that this might return undefined if sheet does not exist
        throw new Error(translationKeys.VDLANG_DATA_IMPORT_CONFIG_SHEET_NOT_FOUND);
    }
    const config = extractConfig(configSheet);

    const mainSheet = workbook.getWorksheet("Valuedesk");

    if (mainSheet == null) {
        // Typings do not reflect that this might return undefined if sheet does not exist
        throw new Error(translationKeys.VDLANG_DATA_IMPORT_MAIN_SHEET_NOT_FOUND);
    }
    const mainData = extractMain(mainSheet);

    return { config, data: mainData };
}

// Read the config sheet and extract the configuration values
function extractConfig(configSheet: ExcelJS.Worksheet): ExcelImportConfig {
    const config: ExcelImportConfig = {
        templateType: TemplateType.MeasureEffects,
        measureConfigId: 0,
        fieldDisplayNames: [],
        fieldInternalFields: [],
    };

    configSheet.eachRow((row) => {
        const values = row.values as CellValue[];
        switch (values[1]) {
            case ConfigTemplateTypeKey:
                {
                    const tt = typeof values[2] == "string" ? values[2] : "";
                    config.templateType = parseTemplateType(tt);
                }
                break;
            case ConfigMeasureConfigIdKey: // Key for measures
                config.measureConfigId = typeof values[2] == "number" ? values[2] : -1;
                break;
            case ConfigFieldNamesKey: // Name<->Field Mapping
                {
                    const fields = values.slice(2);
                    config.fieldDisplayNames = fields.map((s) => s?.valueOf() as string);
                }
                break;
            case ConfigFieldFieldsKey: {
                // Field<->Name Mapping
                const fields = values.slice(2);
                config.fieldInternalFields = fields.map((s) => s?.valueOf() as string);
            }
        }
    });

    // We should never reach this point but just in case
    if (config.fieldInternalFields.length != config.fieldDisplayNames.length) {
        throw new Error("Something went horribly wrong.");
    }

    return config;
}

function parseTemplateType(tt: string): TemplateType {
    switch (tt) {
        case TemplateType.MeasureEffects.toString():
            return TemplateType.MeasureEffects;
        case TemplateType.MeasureFields.toString():
            return TemplateType.MeasureFields;
        case TemplateType.Opps.toString():
            return TemplateType.Opps;
        default:
            throw new Error("Unknown template type.");
    }
}

// Read the measure/opps main data sheet and extract it to cells dictionary
function extractMain(mainSheet: ExcelJS.Worksheet) {
    if (mainSheet.rowCount < 2) {
        return []; // No data
    }

    // Get the column names from first row
    const data: ParsedExcelDataRow[] = [];
    const columns: string[] = [];
    mainSheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => columns.push(cell.text));

    // Iterate over the rows and extract the cells
    mainSheet.eachRow((row, rowNumber) => {
        if (rowNumber == 1) {
            return;
        }

        const rowData: ParsedExcelDataRow = {};
        row.eachCell(function (cell, colNumber) {
            if (cell.text !== "") {
                // Use text because value could be something like { text, hyperlink } or worse
                // colNumber is 1-based
                rowData[columns[colNumber - 1]] = cell.text;
            }
        });

        if (Object.keys(rowData).length > 0) {
            data.push(rowData);
        }
    });

    return data;
}
