import { useTranslation } from "react-i18next";

import { Button, CircularProgress, Divider, Stack, styled, Typography } from "@mui/material";
import { translationKeys } from "../../../translations/main-translations";

import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { floor, groupBy } from "lodash";
import { useSnackbar } from "notistack";
import { ImportTableData } from "./ImportTableWithHeader";
import { ImportTableStatus } from "./table_utils";

interface IImportTableHeaderProps {
    validatedData: ImportTableData[];
    onImportClick: () => void;
    onClearExcelData: () => void;
    onImportCancelClick: () => void;
    onImportResumeClick: () => void;
}

const Header = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(2),
    height: theme.spacing(6),
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const ImportTableHeader = ({
    validatedData,
    onImportClick,
    onClearExcelData,
    onImportCancelClick,
    onImportResumeClick,
}: IImportTableHeaderProps) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const hasError = validatedData.some((dat) => Object.values(dat.fields).filter((value) => value.errorMessage !== "").length > 0);
    const numberOfItems = validatedData.length;
    const statusGroups = groupBy(validatedData, (data) => data.status.value);
    const numberOfItemsWithErrors = statusGroups[ImportTableStatus.Error]?.length ?? 0;
    const numberOfCancelledItems = statusGroups[ImportTableStatus.Cancelled]?.length ?? 0;
    const numberOfPendingItems = statusGroups[ImportTableStatus.Pending]?.length ?? 0;
    const numberOfUploadedItems = statusGroups[ImportTableStatus.Success]?.length ?? 0;

    const isImportStarted = numberOfPendingItems > 0 || numberOfUploadedItems > 0 || numberOfItemsWithErrors > 0;
    const isImportFinished = numberOfUploadedItems + numberOfItemsWithErrors + numberOfCancelledItems === numberOfItems;

    const handleClearExcelData = () => {
        onClearExcelData();
        enqueueSnackbar(t(translationKeys.VDLANG_DATA_IMPORT_INFO_DELETED, { count: numberOfItems }), { variant: "success" });
    };

    return (
        <Header direction="row" alignItems="center" justifyContent="space-between" height="100%">
            <Stack direction="row" divider={<Divider orientation="vertical" sx={{ py: 1.25, mx: 1.25 }} />}>
                {isImportStarted && !isImportFinished ? (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <CircularProgress size={16} variant="determinate" value={(100 * numberOfUploadedItems) / numberOfItems} />
                        <Typography variant="body2" fontWeight="medium" noWrap>
                            {floor((100 * numberOfUploadedItems) / numberOfItems, 0)}%
                        </Typography>
                    </Stack>
                ) : null}
                <Typography variant="body2" fontWeight="medium" noWrap>
                    {numberOfItems > 0 && !isImportStarted
                        ? t(translationKeys.VDLANG_DATA_IMPORT_TABLE_NUMBER_ITEMS, {
                              count: numberOfItems,
                          })
                        : null}
                    {numberOfPendingItems > 0 || isImportFinished
                        ? t(translationKeys.VDLANG_DATA_IMPORT_TABLE_NUMBER_UPLOADED_ITEMS, {
                              count: numberOfItems,
                              numberUploadedData: numberOfUploadedItems,
                          })
                        : null}
                </Typography>
                {numberOfItemsWithErrors > 0 ? (
                    <Stack direction="row" alignItems="center">
                        <ErrorOutline color="error" sx={{ fontSize: "1rem", mr: 0.5 }} />
                        <Typography variant="body2" fontWeight="medium" color="error" noWrap>
                            {t(translationKeys.VDLANG_DATA_IMPORT_TABLE_NUMBER_ERROR_ITEMS, {
                                count: numberOfItemsWithErrors,
                            })}
                        </Typography>
                    </Stack>
                ) : null}
            </Stack>
            <Stack direction="row" spacing={2}>
                {numberOfPendingItems === 0 ? (
                    <Button size="small" variant="outlined" color="error" onClick={handleClearExcelData}>
                        {t(translationKeys.VDLANG_DATA_IMPORT_TABLE_CLEAR_DATA)}
                    </Button>
                ) : null}
                {validatedData.length !== 0 && numberOfPendingItems === 0 && !isImportFinished ? (
                    <Button size="small" variant="contained" onClick={onImportClick} disabled={hasError}>
                        {t(translationKeys.VDLANG_IMPORT)}
                    </Button>
                ) : null}
                {numberOfPendingItems > 0 ? (
                    <Button size="small" variant="outlined" onClick={onImportCancelClick}>
                        {t(translationKeys.VDLANG_DATA_IMPORT_BUTTON_CANCEL)}
                    </Button>
                ) : null}
                {numberOfPendingItems === 0 && numberOfCancelledItems > 0 ? (
                    <Button size="small" variant="outlined" onClick={onImportResumeClick}>
                        {t(translationKeys.VDLANG_DATA_IMPORT_BUTTON_RESUME)}
                    </Button>
                ) : null}
            </Stack>
        </Header>
    );
};

export default ImportTableHeader;
