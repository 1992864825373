import { useTranslation } from "react-i18next";
import useNavigationPrompt from "../../../hooks/useNavigationPrompt";
import { translationKeys } from "../../../translations/main-translations";
import { ImportTableData } from "./ImportTableWithHeader";
import { ImportTableStatus } from "./table_utils";

const useUploadNavigationPrompt = (data: ImportTableData[]) => {
    const { t: translate } = useTranslation();

    const isInProgress = data.some(
        ({ status: { value } }) => value === ImportTableStatus.NotStarted || value === ImportTableStatus.Pending,
    );

    useNavigationPrompt(isInProgress, translate(translationKeys.VDLANG_DATA_IMPORT_NAVIGATION_PROMPT));
};

export default useUploadNavigationPrompt;
