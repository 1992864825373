import { useMutation } from "@tanstack/react-query";
import {
    CreateMeasureDtoV1,
    DeleteMeasureEffectDtoV1,
    EditMeasureDtoV1,
    ErrorConstantKeys,
    NumericDisplayIdSelfDtoV1,
    UpdateMeasureEffectDtoV1,
    UpdateMeasureFieldDtoV1,
} from "api-shared";
import { apiDelete, apiPatch, apiPost } from "../../lib/api";

const MEASURE_PATH = "/v1/measure";

interface MeasureMutationOptions {
    ignoreErrors?: boolean;
}

// createMeasure
export const useCreateMeasure = () => {
    return useMutation({
        mutationFn: (body: CreateMeasureDtoV1) => apiPost<NumericDisplayIdSelfDtoV1>(MEASURE_PATH, body),
    });
};

// updateMeasure
export const useEditMeasure = (options?: MeasureMutationOptions) => {
    return useMutation({
        mutationFn: ({ id, ...body }: EditMeasureDtoV1 & { id: number }) => apiPatch<void>(`${MEASURE_PATH}/${id}`, body),
        meta: {
            ignoreErrors: options?.ignoreErrors,
        },
    });
};

// updateMeasureEffect
export const useEditMeasureEffect = () => {
    return useMutation({
        mutationFn: ({ id, ...body }: UpdateMeasureEffectDtoV1 & { id: number }) => apiPost<void>(`${MEASURE_PATH}/${id}/effect`, body),
        meta: {
            skipReportToSentry: [
                ErrorConstantKeys.VDERROR_BAD_REQUEST_NEW_MEASURE_ASSIGNEE_NEEDED,
                ErrorConstantKeys.VDERROR_CONFLICT_LEVEL_LOCKED,
                ErrorConstantKeys.VDERROR_CONFLICT_EFFECT_CATEGORIES_LOCKED,
            ],
        },
    });
};

// deleteMeasureEffect
export const useDeleteMeasureEffect = () => {
    return useMutation({
        mutationFn: ({ id, ...body }: DeleteMeasureEffectDtoV1 & { id: number }) => apiDelete<void>(`${MEASURE_PATH}/${id}/effect`, body),
        meta: {
            skipReportToSentry: [
                ErrorConstantKeys.VDERROR_BAD_REQUEST_NEW_MEASURE_ASSIGNEE_NEEDED,
                ErrorConstantKeys.VDERROR_CONFLICT_LEVEL_LOCKED,
                ErrorConstantKeys.VDERROR_CONFLICT_EFFECT_CATEGORIES_LOCKED,
            ],
        },
    });
};

// updateMeasureField
export const useEditMeasureField = (options?: MeasureMutationOptions) => {
    return useMutation({
        mutationFn: ({ id, ...body }: UpdateMeasureFieldDtoV1 & { id: number }) => apiPost<void>(`${MEASURE_PATH}/${id}/field`, body),
        meta: {
            ignoreErrors: options?.ignoreErrors,
        },
    });
};
