import { useTranslation } from "react-i18next";

import { Grid, styled, tableCellClasses } from "@mui/material";
import { CellProps } from "react-table";
import BaseTable from "../../../components/table/BaseTable";
import TableHeaderCell from "../../../components/table/TableHeaderCell";
import { translationKeys } from "../../../translations/main-translations";
import ImportTableBaseCellWrapper from "./ImportTableBaseCellWrapper";

import { useMemo } from "react";
import TableTextCell from "../../../components/table/TableTextCell";
import { IdFieldInternalName, ParsedExcelData, TitleFieldInternalName } from "../../../lib/excel";
import { ValidatedExcelData } from "../../../lib/excel-validate";
import ImportTableHeader from "./ImportTableHeader";
import ImportTableIdCell from "./ImportTableIdCell";
import ImportTableLinkCell from "./ImportTableLinkCell";
import ImportTableStatusCell from "./ImportTableStatusCell";
import { ImportTableStatus } from "./table_utils";

export const getValidatedDataWithStatus = (validatedExcelData: ValidatedExcelData[]) =>
    validatedExcelData.map((data) => ({
        ...data,
        status: { value: ImportTableStatus.NotStarted, errorMessage: "" },
    }));

interface IImportTableWithHeaderProps {
    validatedData: ImportTableData[];
    parsedExcelData: ParsedExcelData;
    onImportClick: () => void;
    onClearExcelData: () => void;
    onImportCancelClick: () => void;
    onImportResumeClick: () => void;
}

export type ImportTableData = ValidatedExcelData & {
    status: { value: ImportTableStatus; errorMessage: string };
};

const RootGrid = styled(Grid)({ height: "100%" });
const Grow = styled(Grid)({
    flexGrow: 1,
    flexShrink: 1,
});
const FullHeightCellBaseTable = styled(BaseTable)({
    // padding needs to be removed, because the error state should fill the whole cell
    [`& .${tableCellClasses.body}`]: {
        padding: 0,
        height: "100%",
    },
});

const ImportTableWithHeader = ({
    validatedData,
    parsedExcelData,
    onImportClick,
    onClearExcelData,
    onImportCancelClick,
    onImportResumeClick,
}: IImportTableWithHeaderProps) => {
    const { t } = useTranslation();

    const columns: any = useMemo(
        () => [
            {
                Header: TableHeaderCell,
                id: "status",
                accessor: "status",
                Cell: (props: CellProps<ImportTableData>) => (
                    <ImportTableBaseCellWrapper
                        errorMessage={props.value?.errorMessage ?? ""}
                        // do not feed in status prop because status cell should not be affected by opacity
                    >
                        <ImportTableStatusCell {...props} apiId={props.row.original.apiId} />
                    </ImportTableBaseCellWrapper>
                ),
                label: "Status",
                width: 70,
            },
            ...parsedExcelData.config.fieldInternalFields.map((field, index) => {
                if (field === IdFieldInternalName) {
                    return {
                        Header: TableHeaderCell,
                        id: index.toString(),
                        accessor: `fields.${field}`,
                        Cell: (props: CellProps<ImportTableData>) => (
                            <ImportTableBaseCellWrapper
                                errorMessage={props.value?.errorMessage ?? ""}
                                status={props.row.original.status.value}
                            >
                                <ImportTableIdCell {...props} templateType={parsedExcelData.config.templateType} />
                            </ImportTableBaseCellWrapper>
                        ),
                        label: field,
                        width: 50,
                    };
                }

                if (field === TitleFieldInternalName) {
                    return {
                        Header: TableHeaderCell,
                        id: index.toString(),
                        accessor: `fields.${field}`,
                        Cell: (props: CellProps<ImportTableData>) => (
                            <ImportTableBaseCellWrapper
                                errorMessage={props.value?.errorMessage ?? ""}
                                status={props.row.original.status.value}
                            >
                                <ImportTableLinkCell
                                    {...props}
                                    apiId={props.row.original.apiId}
                                    templateType={parsedExcelData.config.templateType}
                                />
                            </ImportTableBaseCellWrapper>
                        ),
                        label: parsedExcelData.config.fieldDisplayNames[index],
                        width: 320,
                    };
                }

                return {
                    Header: TableHeaderCell,
                    id: index.toString(),
                    accessor: `fields.${field}`,
                    Cell: (props: CellProps<ImportTableData>) => (
                        <ImportTableBaseCellWrapper errorMessage={props.value?.errorMessage ?? ""} status={props.row.original.status.value}>
                            <TableTextCell {...props} value={props.value?.value} />
                        </ImportTableBaseCellWrapper>
                    ),
                    label: parsedExcelData.config.fieldDisplayNames[index],
                };
            }),
        ],
        [parsedExcelData.config.fieldDisplayNames, parsedExcelData.config.fieldInternalFields, parsedExcelData.config.templateType],
    );

    return (
        <RootGrid container direction="column" wrap="nowrap">
            <ImportTableHeader
                onClearExcelData={onClearExcelData}
                onImportCancelClick={onImportCancelClick}
                onImportClick={onImportClick}
                onImportResumeClick={onImportResumeClick}
                validatedData={validatedData}
            />
            <Grow item>
                <FullHeightCellBaseTable
                    columns={columns}
                    translate={t}
                    data={validatedData}
                    noDataText={t(translationKeys.VDLANG_DATA_IMPORT_TABLE_NO_DATA)}
                    pageSizeOptions={[25, 50, 100]}
                />
            </Grow>
        </RootGrid>
    );
};

export default ImportTableWithHeader;
