import AddCircleOutlineRounded from "@mui/icons-material/AddCircleOutlineRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import UpdateOutlined from "@mui/icons-material/UpdateOutlined";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";
import Tooltip from "../../../components/Tooltip";
import { ValidatedExcelDataCell } from "../../../lib/excel-validate";
import { translationKeys } from "../../../translations/main-translations";
import { ImportTableData } from "./ImportTableWithHeader";
import { ImportTableStatus } from "./table_utils";

const ImportTableStatusCell = ({
    value,
    apiId,
    row,
}: CellProps<ImportTableData, ValidatedExcelDataCell> & { apiId: number | undefined }) => {
    const hasAnyError = row.cells.some((cell) => cell.value != undefined && cell.value.errorMessage !== "");

    const { t } = useTranslation();

    if (value?.value === ImportTableStatus.Pending) {
        return (
            <Tooltip title={t(translationKeys.VDLANG_DATA_IMPORT_TABLE_STATUS_PENDING)}>
                <CircularProgress size={24} />
            </Tooltip>
        );
    }

    if (value?.value === ImportTableStatus.Cancelled) {
        return (
            <Tooltip title={t(translationKeys.VDLANG_DATA_IMPORT_TABLE_STATUS_CANCELLED)}>
                <HighlightOffRoundedIcon color="error" />
            </Tooltip>
        );
    }

    if (apiId !== undefined) {
        return (
            <Tooltip title={t(translationKeys.VDLANG_DATA_IMPORT_TABLE_STATUS_UPDATE)}>
                <UpdateOutlined color={hasAnyError ? "error" : "action"} />
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title={t(translationKeys.VDLANG_DATA_IMPORT_TABLE_STATUS_ADD)}>
                <AddCircleOutlineRounded color={hasAnyError ? "error" : "action"} />
            </Tooltip>
        );
    }
};

export default ImportTableStatusCell;
