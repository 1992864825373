import { Stack, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import BannerLayout from "../../components/BannerLayout";
import { useMeasureConfigsV1 } from "../../domain/v1/measure_config";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { translationKeys } from "../../translations/main-translations";
import GenerateTemplateDialog from "./GenerateTemplateDialog";
import ImportFileSection from "./ImportFileSection";

const Header = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 6),
    // use fixed height + flexbox instead of "magic" paddings that add up with the children paddings to the desired values
    height: theme.spacing(8),
}));

const Content = styled("div")(({ theme }) => ({
    padding: theme.spacing(2.75, 6, 3),
    overflowX: "hidden",
    height: "100%",
}));

const DataImportView = () => {
    const { t } = useTranslation();
    const measureConfigsQuery = useMeasureConfigsV1();

    useDocumentTitle(translationKeys.VDLANG_DATA_IMPORT_TITLE);

    return (
        <BannerLayout
            banner={
                <Header>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" height="100%">
                        <Typography variant="h5" noWrap>
                            {t(translationKeys.VDLANG_DATA_IMPORT_TITLE)}
                        </Typography>
                        {measureConfigsQuery.isSuccess && measureConfigsQuery.data.length > 0 ? (
                            <GenerateTemplateDialog measureConfigs={measureConfigsQuery.data} />
                        ) : null}
                    </Stack>
                </Header>
            }
        >
            <Content>
                <ImportFileSection />
            </Content>
        </BannerLayout>
    );
};

export default DataImportView;
