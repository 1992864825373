import { Link, Typography } from "@mui/material";
import { CellProps } from "react-table";
import { TemplateType } from "../../../lib/excel";
import { ValidatedExcelDataCell } from "../../../lib/excel-validate";
import { RouteFor } from "../../../lib/routes";
import { ImportTableData } from "./ImportTableWithHeader";

type IImportTableLinkCell = CellProps<ImportTableData, ValidatedExcelDataCell> & { apiId: number | undefined; templateType: TemplateType };

const ImportTableLinkCell = ({ value, apiId, templateType }: IImportTableLinkCell) => {
    return (
        <Typography noWrap>
            {apiId != undefined ? (
                <Link
                    href={templateType === TemplateType.Opps ? RouteFor.opportunities.forId(apiId) : RouteFor.measure.forId(apiId)}
                    target="_blank"
                >
                    {value?.value ?? ""}
                </Link>
            ) : (
                (value?.value ?? "")
            )}
        </Typography>
    );
};

export default ImportTableLinkCell;
