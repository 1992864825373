import { DialogContentText, FormControl, FormLabel, styled } from "@mui/material";
import { MeasureCalculationGranularity, MeasureConfigListDtoV1 } from "api-shared";
import { TFunction } from "i18next";

import { Moment } from "moment";
import ErrorBanner from "../../components/ErrorBanner";
import CalculationTimerangeSelect from "../../components/input/date/CalculationTimerangeSelect";
import { ISelectProps, Option, ValueHandler } from "../../components/input/select/types";
import { translationKeys } from "../../translations/main-translations";
import MeasureConfigSelect from "./MeasureConfigSelect";

interface IMeasureConfigSelectProps extends Omit<ISelectProps<Option<number>>, "options" | "value" | "onChange"> {
    measureConfigValue: number;
    onMeasureConfigChanged: ValueHandler<number>;
    onStartChanged: (newStart: Moment) => void;
    onEndChanged: (newEnd: Moment) => void;
    start: Moment | null;
    end: Moment | null;
    translate: TFunction;
    measureConfigs: MeasureConfigListDtoV1;
    processTypeHasNoEffects?: boolean;
}

const DatePicker = styled(CalculationTimerangeSelect)(({ theme }) => ({
    width: "100%",
}));

const MeasureCalculationDataTabContent = ({
    measureConfigValue,
    translate,
    onMeasureConfigChanged,
    measureConfigs,
    start,
    end,
    onStartChanged,
    onEndChanged,
    processTypeHasNoEffects,
}: IMeasureConfigSelectProps) => {
    return (
        <>
            <DialogContentText>{translate(translationKeys.VDLANG_DATA_IMPORT_DIALOG_PROCESS_CALCULATION_DESC)}</DialogContentText>
            <MeasureConfigSelect
                measureConfigs={measureConfigs}
                value={measureConfigValue}
                onChange={onMeasureConfigChanged}
                translate={translate}
            />
            {processTypeHasNoEffects ? (
                <ErrorBanner>{translate(translationKeys.VDLANG_DATA_IMPORT_DIALOG_PROCESS_CALCULATION_NO_EFFECTS)}</ErrorBanner>
            ) : null}
            <FormControl margin="normal" sx={{ width: "100%" }}>
                <FormLabel sx={{ mb: 0.25 }}>
                    {translate(translationKeys.VDLANG_DATA_IMPORT_DIALOG_PROCESS_CALCULATION_EFFECT_DATE_RANGE)}
                </FormLabel>
                <DatePicker
                    translate={translate}
                    end={end}
                    start={start}
                    granularity={MeasureCalculationGranularity.MONTH}
                    fiscalYearStart={0}
                    onStartChanged={onStartChanged}
                    onEndChanged={onEndChanged}
                />
            </FormControl>
        </>
    );
};

export default MeasureCalculationDataTabContent;
