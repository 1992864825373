import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { TypographyProps, alpha, styled } from "@mui/material";
import { CellProps } from "react-table";
import Tooltip from "../../../components/Tooltip";
import { ImportTableStatus } from "./table_utils";

export interface ImportTableBaseCellWrapperProps<D extends object = Record<string, unknown>>
    extends Partial<Pick<CellProps<D>, "value" | "cell" | "row">>,
        Pick<TypographyProps, "align" | "variant" | "className"> {
    children?: any;
    errorMessage: string;
    status?: ImportTableStatus;
}

const CellWrapper = styled("div", {
    shouldForwardProp: (prop) => prop !== "status",
})<{ status: ImportTableStatus }>(({ theme, status }) => ({
    backgroundColor: status === ImportTableStatus.Error ? alpha(theme.palette.error.main, 0.08) : theme.palette.background.paper,
    borderBottom: status === ImportTableStatus.Error ? `1px solid ${theme.palette.error.main}` : "none",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 1.5),
    height: "100%",
    opacity: status === ImportTableStatus.Pending || status === ImportTableStatus.Cancelled ? 0.5 : 1,
}));

const ErrorIcon = styled(ErrorOutline)(({ theme }) => ({
    color: theme.palette.error.main,
}));

const ImportTableBaseCellWrapper = ({ errorMessage, status, children }: ImportTableBaseCellWrapperProps) => {
    return (
        <CellWrapper
            status={status !== undefined && errorMessage !== "" ? ImportTableStatus.Error : (status ?? ImportTableStatus.NotStarted)}
        >
            <Tooltip title={errorMessage}>
                {status === undefined && errorMessage !== "" ? <ErrorIcon fontSize="inherit" /> : <span>{children}</span>}
            </Tooltip>
        </CellWrapper>
    );
};

export default ImportTableBaseCellWrapper;
