import { useInfiniteQuery } from "@tanstack/react-query";
import { MeasureDtoV1, SearchDtoV1 } from "api-shared";
import { apiPost } from "../../lib/api";

const SEARCH_PATH = "/v1/search";

export const MeasureSearchQueryKeys = {
    all: [SEARCH_PATH] as const,
    search: (body: SearchDtoV1) => [...MeasureSearchQueryKeys.all, "search", body] as const,
};

export const useMeasureSearchQuery = (body: SearchDtoV1, enabled = true) => {
    const fetchMeasureSearch = async (body: SearchDtoV1, pageParam: number) => {
        return apiPost<MeasureDtoV1[]>(SEARCH_PATH, { ...body, startAt: pageParam });
    };

    return useInfiniteQuery({
        queryKey: MeasureSearchQueryKeys.search(body),
        queryFn: ({ pageParam = 0, queryKey }) => fetchMeasureSearch(queryKey[2], pageParam),
        getNextPageParam: (lastPage, pages) =>
            lastPage.length >= body.maxResults ? Math.ceil(pages.flat().length / body.maxResults) : undefined,
        keepPreviousData: true,
        enabled,
    });
};
