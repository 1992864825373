import { Box, DialogContentText, Tab, Tabs, styled } from "@mui/material";
import { Moment } from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultButton from "../../components/DefaultButton";
import ActionItemDialog from "../../components/dialogues/ActionItemDialog";
import useDialog from "../../hooks/useDialog";

import { MeasureConfigListDtoV1 } from "api-shared";
import { baseDialogClasses } from "../../components/dialogues/BaseDialog";
import { useMeasureConfigsEffectsV1, useMeasureConfigsFieldsV1 } from "../../domain/v1/measure_config";
import { useDownloadFile } from "../../hooks/useDownloadFile";
import { createMeasureEffectsTemplate, createMeasureFieldsTemplate, createOppsTemplate } from "../../lib/excel-generate";
import { translationKeys } from "../../translations/main-translations";
import { useIdeaEstimates } from "../ideas/hooks";
import MeasureCalculationDataTabContent from "./MeasureCalculationDataTabContent";
import MeasureConfigSelect from "./MeasureConfigSelect";

interface IGenerateTemplateDialogProps {
    measureConfigs: MeasureConfigListDtoV1;
}

const MinHeightDialog = styled(ActionItemDialog)(({ theme }) => ({
    [`& .${baseDialogClasses.paper}`]: {
        minHeight: theme.spacing(60),
    },
}));

const DialogTabsWrapper = styled(Box)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: "100%",
    background: theme.palette.background.default,
}));

const DenseTabs = styled(Tabs)(({ theme }) => ({
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: theme.spacing(5.25),
    minHeight: theme.spacing(5.25),
}));

const DenseTab = styled(Tab)(({ theme }) => ({
    minHeight: theme.spacing(5.25),
}));

const convertMomentToMonthAndYear = (moment: Moment): [number, number] => {
    return [Number(moment.format("M")) - 1, Number(moment.format("YYYY"))];
};

const GenerateTemplateDialog = ({ measureConfigs }: IGenerateTemplateDialogProps) => {
    const { t } = useTranslation();

    const { isOpen, close, open } = useDialog();
    const [tabValue, setTabValue] = React.useState(0);

    const [selectedMeasureConfigId, setSelectedMeasureConfigId] = useState<number>(measureConfigs[0].id);
    const [start, setStart] = useState<Moment | null>(null);
    const [end, setEnd] = useState<Moment | null>(null);

    const estimates = useIdeaEstimates();

    function getFilename(type: "Field" | "Effect") {
        const index = measureConfigs.findIndex(({ id }) => id === selectedMeasureConfigId) ?? 0;
        const prefix = t(measureConfigs[index].name).replace("-", "");
        return `${prefix + type}Template.xlsx`;
    }

    const download = useDownloadFile();

    const effectMetaQuery = useMeasureConfigsEffectsV1(selectedMeasureConfigId);
    const fieldMetaQuery = useMeasureConfigsFieldsV1(selectedMeasureConfigId);

    const handleClose = () => {
        close();
        setTabValue(0);
        setStart(null);
        setEnd(null);
    };

    const handleSubmitDialog = async () => {
        let blob;

        switch (tabValue) {
            case 0:
                if (!fieldMetaQuery.isSuccess) {
                    return;
                }
                blob = await createMeasureFieldsTemplate(fieldMetaQuery.data, selectedMeasureConfigId, t);
                if (blob !== undefined) {
                    const filename = getFilename("Field");
                    download(filename, blob);
                }
                break;
            case 1:
                if (start === null || end === null) {
                    return;
                }

                if (!effectMetaQuery.isSuccess) {
                    return;
                }

                blob = await createMeasureEffectsTemplate(
                    effectMetaQuery.data,
                    selectedMeasureConfigId,
                    ...convertMomentToMonthAndYear(start),
                    ...convertMomentToMonthAndYear(end),
                    t,
                );
                if (blob !== undefined) {
                    const filename = getFilename("Effect");
                    download(filename, blob);
                }
                break;
            case 2:
                blob = await createOppsTemplate(estimates);
                if (blob !== undefined) {
                    download("OpportunityTemplate.xlsx", blob);
                }
                break;
        }

        handleClose();
    };

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const processTypeHasNoEffects = effectMetaQuery.isSuccess ? effectMetaQuery.data.generations.length === 0 : undefined;
    const isInvalidEffectsConfig = tabValue === 1 && (start === null || end === null || processTypeHasNoEffects);
    const isMetaDataLoading = (tabValue === 0 && !fieldMetaQuery.isSuccess) || (tabValue === 1 && !effectMetaQuery.isSuccess);

    return (
        <>
            <DefaultButton color="inherit" onClick={open}>
                {t(translationKeys.VDLANG_DATA_IMPORT_DIALOG_TITLE)}
            </DefaultButton>
            <MinHeightDialog
                title={t(translationKeys.VDLANG_DATA_IMPORT_DIALOG_TITLE)}
                primary={translationKeys.VDLANG_DATA_IMPORT_ACTION_GENERATE}
                onPrimary={handleSubmitDialog}
                primaryDisabled={isMetaDataLoading || isInvalidEffectsConfig}
                translate={t}
                open={isOpen}
                onClose={handleClose}
                disableContentPadding
            >
                <DialogTabsWrapper>
                    <DenseTabs variant="fullWidth" value={tabValue} onChange={handleTabChange} role="tabpanel">
                        <DenseTab label={t(translationKeys.VDLANG_DATA_IMPORT_DIALOG_PROCESS_MAIN)} />
                        <DenseTab label={t(translationKeys.VDLANG_DATA_IMPORT_DIALOG_PROCESS_CALCULATION)} />
                        <DenseTab label={t(translationKeys.VDLANG_DATA_IMPORT_DIALOG_OPPS)} />
                    </DenseTabs>
                </DialogTabsWrapper>
                <Box sx={{ p: 3 }}>
                    {tabValue === 0 ? (
                        <>
                            <DialogContentText>{t(translationKeys.VDLANG_DATA_IMPORT_DIALOG_PROCESS_MAIN_DESC)}</DialogContentText>
                            <MeasureConfigSelect
                                measureConfigs={measureConfigs}
                                value={selectedMeasureConfigId}
                                onChange={(newValue) => newValue !== null && setSelectedMeasureConfigId(newValue)}
                                translate={t}
                            />
                        </>
                    ) : null}
                    {tabValue === 1 ? (
                        <MeasureCalculationDataTabContent
                            measureConfigs={measureConfigs}
                            measureConfigValue={selectedMeasureConfigId}
                            onMeasureConfigChanged={(newValue) => newValue !== null && setSelectedMeasureConfigId(newValue)}
                            translate={t}
                            end={end}
                            start={start}
                            onStartChanged={setStart}
                            onEndChanged={setEnd}
                            processTypeHasNoEffects={processTypeHasNoEffects}
                        />
                    ) : null}
                    {tabValue === 2 ? (
                        <DialogContentText>{t(translationKeys.VDLANG_DATA_IMPORT_DIALOG_OPPS_DESC)}</DialogContentText>
                    ) : null}
                </Box>
            </MinHeightDialog>
        </>
    );
};

export default GenerateTemplateDialog;
